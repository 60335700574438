import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicComponentItem } from "../../components/utils/dynamic-loader/dynamic-component-item";
import {
  setCurrentBusinessUnit,
  setCurrentPageId,
  setCurrentPageTitle,
  setInPageNavigation,
  setPageType
} from "../../store/global/global.actions";
import { GlobalState } from "../../store/global/global.reducer";
import {
  setCollectionName,
  setSearchResultPage
} from "../../store/search/search.actions";
import { SearchState } from "../../store/search/search.reducer";

@Injectable({
  providedIn: "root"
})
export class ComponentLoaderService {
  layout: DynamicComponentItem | undefined;

  constructor(
    private store: Store<{ global: GlobalState }>,
    private searchStore: Store<{ search: SearchState }>
  ) {}

  async importComponents(data: any) {
    let commonData: any = {};
    if (data) {
      commonData = {
        pageTitle: data.pageTitle,
        contentType: data.contentType,
        seoDescription: data.seoDescription,
        seoKeywords: data.seoKeywords,
        seoAuthor: data.seoAuthor,
        useInPageNavigation: data.useInPageNavigation,
        showBreadcrumbs: data.showBreadcrumbs,
        browserTitle: data.browserTitle,
        changed: data.changed,
        canonicalUrl: data.canonicalUrl,
        noIndex: data.noIndex,
        noFollow: data.noFollow,
        ssKeyHosts: data.ssKeyHosts,
        tealiumHosts: data.tealiumHosts,
        midHosts: data.midHosts,
        cubedHosts: data.cubedHosts,
        schemaAddress: data.schemaAddress,
        schemaBrand: data.schemaBrand,
        schemaEmail: data.schemaEmail,
        contentLink: data.contentLink,
        header: data.header,
        footer: data.footer,
        authors: data.authors,
        authorIntroductionTitle: data.authorIntroductionTitle,
        name: data.name,
        heroContentArea: await this.contentAreaLoader(data.heroContentArea),
        adobeLaunchUrl: data.adobeLaunchUrl,
        activateAdobeTarget: data.activateAdobeTarget,
        customScript: data.customScript,
        businessUnit: data.businessUnit,
        lowerScreenStickyBanner: await this.contentAreaLoader(
          data.lowerScreenStickyBanner
        ),
        headerBanner: await this.contentAreaLoader(data.headerBanner),
        disclaimerPopup: await this.contentAreaLoader(data.disclaimerPopup),
        oneTrustScript: data.oneTrustScript,
        displayByLine: data.displayByLine,
        byLineLabel: data.byLineLabel,
        teaserTitle: data.teaserTitle,
        teaserImage: data.teaserImage,
        category: data.category,
        communityGroups: data.communityGroups,
        indexDate: data.indexDate
      };

      if (data.filterBy) {
        commonData = {
          ...commonData,
          filterBy: data.filterBy
        };
      }

      if (data.pageStyle) {
        commonData = {
          ...commonData,
          pageStyle: data.pageStyle
        };
      }

      if (data.allArticlesFilterLabel) {
        commonData = {
          ...commonData,
          allArticlesFilterLabel: data.allArticlesFilterLabel
        };
      }

      if (data.filterCategories) {
        commonData = {
          ...commonData,
          filterCategories: data.filterCategories
        };
      }

      if (data.nestedIndexPages) {
        commonData = {
          ...commonData,
          nestedIndexPages: data.nestedIndexPages
        };
      }

      if (data.businessUnit) {
        commonData = {
          ...commonData,
          businessUnit: data.businessUnit
        };
        this.store.dispatch(
          setCurrentBusinessUnit({ businessUnit: data.businessUnit })
        );
      }

      if (data.contentLink) {
        this.store.dispatch(
          setCurrentPageId({ pageId: data.contentLink["id"] })
        );
      }

      if (data.pageTitle) {
        this.store.dispatch(setCurrentPageTitle({ pageTitle: data.pageTitle }));
      }

      if (data.contentType) {
        this.store.dispatch(setPageType({ pageType: data.contentType[1] }));
      }

      if (
        data.header &&
        data.header.searchResultsPage &&
        data.header.searchResultsPage.url
      ) {
        const url = data.header.searchResultsPage.url.replace(
          /^.*\/\/[^/]+/,
          ""
        );
        this.searchStore.dispatch(
          setSearchResultPage({ searchResultPage: url })
        );
      }

      if (data.header && data.header.collectionName) {
        this.searchStore.dispatch(
          setCollectionName({ collectionName: data.header.collectionName })
        );
      }

      if (data.useInPageNavigation) {
        this.setInPageNavigationList(data);
        commonData = {
          ...commonData,
          inPageNavigationMobileLabel: data.inPageNavigationMobileLabel,
          inPageNavigationCta: data.inPageNavigationCta
        };
      }

      if (data.showBreadcrumbs) {
        commonData = {
          ...commonData,
          breadcrumbs: data.breadcrumbs
        };
      }

      if (
        data.footer?.footerDisclaimerText &&
        data.footer?.showDisclaimerAtTop
      ) {
        commonData = {
          ...commonData,
          disclaimerComponent: await this.loadComponent(
            "DisclaimerLayout",
            "layouts/partials",
            {
              footerDisclaimerText: data.footer?.footerDisclaimerText
            }
          )
        };
      }

      if (
        data &&
        data.contentType &&
        (data.contentType.includes("StandardPage") ||
          data.contentType.includes("CampaignPage"))
      ) {
        this.layout = await this.loadComponent("StandardLayout", "layouts", {
          ...commonData,
          contentArea1Layout: data.contentArea1Layout,
          contentArea2Layout: data.contentArea2Layout,
          contentArea3Layout: data.contentArea3Layout,
          contentArea4Layout: data.contentArea4Layout,
          contentArea1: await this.contentAreaLoader(data.contentArea1),
          contentArea2: await this.contentAreaLoader(data.contentArea2),
          contentArea3: await this.contentAreaLoader(data.contentArea3),
          contentArea4: await this.contentAreaLoader(data.contentArea4),
          columnContentArea1: await this.contentAreaLoader(
            data.columnContentArea1
          ),
          columnContentArea2: await this.contentAreaLoader(
            data.columnContentArea2
          ),
          columnContentArea3: await this.contentAreaLoader(
            data.columnContentArea3
          ),
          columnContentArea4: await this.contentAreaLoader(
            data.columnContentArea4
          ),
          headerStyle: data.headerStyle,
          ctaButtons: data.ctaButtons,
          secondaryPartnerLogo: data.secondaryPartnerLogo,
          secondaryPartnerLogoAltText: data.secondaryPartnerLogoAltText
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("ArticlePage")
      ) {
        this.layout = await this.loadComponent("ArticleLayout", "layouts", {
          ...commonData,
          articleDate: data.articleDate,
          readTime: data.readTime,
          showCategoriesOnPage: data.showCategoriesOnPage,
          showShareIcons: data.showShareIcons,
          introduction: data.introduction,
          mainImage: data.mainImage,
          mainImageCaption: data.mainImageCaption,
          headerStyle: data.headerStyle,
          ctaButtons: data.ctaButtons,
          tagRootPage: data.tagRootPage,
          body1: data.body1,
          body2: data.body2,
          body3: data.body3,
          body4: data.body4,
          body5: data.body5,
          mainContent1: await this.contentAreaLoader(data.mainContent1),
          mainContent2: await this.contentAreaLoader(data.mainContent2),
          mainContent3: await this.contentAreaLoader(data.mainContent3),
          mainContent4: await this.contentAreaLoader(data.mainContent4),
          mainContent5: await this.contentAreaLoader(data.mainContent5),
          bottomFullWidthContent: await this.contentAreaLoader(
            data.bottomFullWidthContent
          )
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("AuthorPage")
      ) {
        this.layout = await this.loadComponent("AuthorLayout", "layouts", {
          ...commonData,
          authorTitle: data.authorTitle,
          authorName: data.authorFirstName,
          authorFirstName: data.authorFirstName,
          authorSurname: data.authorSurname,
          jobTitle: data.jobTitle,
          department: data.department,
          businessArea: data.businessArea,
          authorProfileImage: data.authorProfileImage,
          linkedInProfile: data.linkedInProfile,
          twitterXProfile: data.twitterXProfile,
          bioIntro: data.bioIntro,
          fullBio: data.fullBio,
          tag: data.tag,
          showAuthorsContent: data.showAuthorsContent,
          content: await this.contentAreaLoader(data.content)
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("LenderPage")
      ) {
        this.layout = await this.loadComponent("LenderLayout", "layouts", {
          ...commonData,
          lenderName: data.lenderName,
          lenderDescription: data.lenderDescription,
          selectedPartnersOnly: data.selectedPartnersOnly,
          lenderLogo: data.lenderLogo,
          hideFromLenderDirectory: data.hideFromLenderDirectory,
          registerNewUserText: data.registerNewUserText,
          registerNewUserCta: data.registerNewUserCta,
          registerPaymentRouteText: data.registerPaymentRouteText,
          registerPaymentRouteCta: data.registerPaymentRouteCta,
          contactInformationText: data.contactInformationText,
          conactInformationCtas: data.conactInformationCtas,
          procurationFees: data.procurationFees,
          procurationFeesNotes: data.procurationFeesNotes,
          mainBody: data.mainBody,
          ctaButtons: data.ctaButtons,
          contentArea1: await this.contentAreaLoader(data.contentArea1)
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("ArticleIndexPage")
      ) {
        this.layout = await this.loadComponent(
          "ArticleIndexLayout",
          "layouts",
          {
            ...commonData,
            topContentArea: await this.contentAreaLoader(data.topContentArea),
            bottomContentArea: await this.contentAreaLoader(
              data.bottomContentArea
            )
          }
        );
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("SitemapPage")
      ) {
        this.layout = await this.loadComponent("SitemapLayout", "layouts", {
          ...commonData,
          siteMap: data.siteMap
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("SearchResultsPage")
      ) {
        this.layout = await this.loadComponent(
          "SearchResultsLayout",
          "layouts",
          {
            ...commonData,
            prefix: data.prefix,
            infix: data.infix,
            suffix: data.suffix,
            searchAgainTitle: data.searchAgainTitle,
            searchAgainPlaceholderText: data.searchAgainPlaceholderText
          }
        );
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("DocumentLibraryPage")
      ) {
        this.layout = await this.loadComponent(
          "DocumentLibraryLayout",
          "layouts",
          {
            ...commonData,
            subTitle: data.subTitle,
            fileTitleLabel: data.fileTitleLabel,
            fileReferenceTitle: data.fileReferenceTitle,
            showFileDate: data.showFileDate,
            showFileReference: data.showFileReference,
            fileDateLabel: data.fileDateLabel,
            listingTitle: data.listingTitle,
            searchLabel: data.searchLabel,
            categoryFilters: data.categoryFilters,
            filtersLabel: data.filtersLabel,
            clearAllLabel: data.clearAllLabel,
            sortByLabel: data.sortByLabel,
            sortByMostPopularLabel: data.sortByMostPopularLabel,
            sortByFileDateLabel: data.sortByFileDateLabel,
            recentlyViewedLabel: data.recentlyViewedLabel
          }
        );
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("AuthorIndexPage")
      ) {
        this.layout = await this.loadComponent("AuthorIndexLayout", "layouts", {
          ...commonData,
          listTitle: data.listTitle,
          filterByTeams: data.filterByTeams
        });
      } else if (
        data &&
        data.contentType &&
        data.contentType.includes("BlogHomePage")
      ) {
        this.layout = await this.loadComponent("BlogHomeLayout", "layouts", {
          ...commonData,
          listTitle: data.listTitle,
          filterByTeams: data.filterByTeams,
          enableCommunities: data.enableCommunities,
          tagRootPage: data.tagRootPage
        });
      } else {
        this.layout = await this.loadComponent("DefaultLayout", "layouts", {
          ...commonData,
          mainContentArea: await this.contentAreaLoader(data.mainContentArea)
        });
      }
    }
    return this.layout;
  }

  async loadComponent(
    componentToLoad: string,
    componentType: string,
    data: any
  ) {
    try {
      const component = await import(
        `../../components/${componentType}/${componentToLoad}/${componentToLoad}.component`
      );

      return new DynamicComponentItem(
        component[`${componentToLoad}Component`],
        data
      );
    } catch {
      console.error(`${componentToLoad} component was not found`);
      return undefined;
    }
  }

  async contentAreaLoader(contentArea: any, parent?: string) {
    if (contentArea) {
      return await Promise.all(
        contentArea.map(async (block: any) => {
          if (block.contentLink && block.contentLink.expanded) {
            const componentData = block.contentLink.expanded;
            const isImage = componentData.contentType[0] === "Image";
            const isPage = componentData.contentType[0] === "Page";
            const isHeroTabs =
              parent === "HeroBlock" &&
              componentData.contentType[1] === "TabFilterBlock";

            let componentToLoad = componentData.contentType[1];

            if (isImage) {
              componentToLoad = "Image";
            } else if (isPage) {
              componentToLoad = "BoxBlock";
            } else if (isHeroTabs) {
              componentToLoad = "HeroTabs";
            }
            const type = isImage ? "globals" : "blocks";

            return await this.loadComponent(
              componentToLoad,
              type,
              componentData
            );
          }
          return [];
        })
      );
    }
    return [];
  }

  setInPageNavigationList(data: any) {
    const navTitle = data?.inPageNavAnchorTitle;
    if (navTitle) {
      this.store.dispatch(
        setInPageNavigation({
          item: { label: navTitle, active: true }
        })
      );
    }

    if (data instanceof Object) {
      for (const prop in data) {
        if (data[prop] instanceof Array) {
          data[prop].forEach((nestedData: any) => {
            this.setInPageNavigationList(nestedData.contentLink?.expanded);
          });
        }
      }
    }
  }
}
